<template>
  <div>   
    <vs-popup title="Lista de OS Selecionadas" :active.sync="popUpSelecao.popupActive">
          <vs-alert color="danger" :active.sync="popUpSelecao.exibirMensagem" closable icon-pack="feather" close-icon="icon-x" class="mb-3" icon="icon-info">
              {{popUpSelecao.mensagem}}
          </vs-alert> 
          <vs-table :data="listaOSSelecionadas" pagination max-items="10" noDataText="Nenhuma OS selecionada." stripe search>
            <template slot="header">
                <h6 class="mr-auto" v-if="listaOSSelecionadas.length > 0 "> {{listaOSSelecionadas.length}} OS selecionad(s)</h6>                                   
            </template>
            <template slot="thead">
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">OS</span></vs-th>
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">Cliente</span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Data </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Tipo </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Status </span></vs-th>          
            </template>

            <template slot-scope="{data}"> 
                <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                    <vs-td :data="tr.numeroOS"  style="padding: 1px;">
                        <small>{{tr.numeroOS}}</small>                   
                    </vs-td>
                    <vs-td :data="tr.nomeCliente"  style="padding: 1px;" class="text-left">
                        <small>{{tr.nomeCliente}}</small>
                    </vs-td>   
                    <vs-td :data="tr.dataAgendada"  style="padding: 1px;" class="text-center">
                        <small>{{tr.dataAgendada}}</small>
                    </vs-td>   
                    <vs-td :data="tr.status"  style="padding: 1px;" class="text-center">
                        <small>{{tr.tipo}}</small>
                    </vs-td>   
                    <vs-td :data="tr.status"  style="padding: 1px;" class="text-center">
                        <small>{{tr.status}}</small>
                    </vs-td>   
                    <vs-td  style="padding: 1px;">
                        <vs-button size="small" color="danger" icon-pack="feather" icon="icon-minus" @click="removerOS(tr)"></vs-button>             
                    </vs-td>
                </vs-tr>
            </template>
          </vs-table> 
          
          <vs-row vs-type="flex" vs-justify="space-between" class="mt-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">                                    
                  <vs-button class="ml-2" @click="baixarPagamento" icon="attach_money" color="success" v-if="isPodeDarBaixaPgto" :disabled="!listaOSSelecionadas.length"></vs-button>                              
                  <vs-button color="danger" @click="limparListaSelecao" class="ml-2" :disabled="!listaOSSelecionadas.length" icon="clear"> </vs-button>
              </vs-col>
          </vs-row>
    </vs-popup>  
    <vx-card class="mb-1">
      <vs-row vs-type="flex" vs-justify="space-between" >          
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">         
              <label class="vs-input--label mr-2">Tecnico</label>              
          </vs-col>          
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">         
              <label class="vs-input--label mr-2">Status</label>              
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2">Tipo</label>              
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">         
              
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
          </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" >          
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">         
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.tecnico" @keyup.enter="fetchLista(filtro)" />
          </vs-col>          
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">         
              <v-select                                               
                :options="CONSTANTE_STATUS_OS" 
                v-model="filtro.status"                      
                class="w-full vs-input--label">
              </v-select> 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
              <v-select                                               
                :options="CONSTANTE_TIPO_OS" 
                v-model="filtro.tipoOS"                      
                class="w-full vs-input--label">
              </v-select> 
          </vs-col>          
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">         
              <vs-checkbox v-model="filtro.comissaoPaga" class="w-full"><small>Comissão Paga</small></vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
            <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
            <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>              
            <vs-button class="mr-2" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>   
            <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-plus" @click="adicionarTodos()"></vs-button>             
            <feather-icon icon="ArchiveIcon" class="cursor-pointer ml-4 mr-6" :badge="listaOSSelecionadas.length" @click="abrirPopUpSelecao" />       
          </vs-col>
      </vs-row>
    </vx-card>
     
    <h6 class="mt-2 text-danger"> {{qtdOSPesquisadas }} registros </h6>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-2"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="22"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"      
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      :context="configuracaoGrid.context"
      >
    </ag-grid-vue>      
    
  </div>
  

</template>

<script>
import axios from "@/axios.js"
import generico from "@/generico.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import BtnAggridComponentRenderer from './BtnAggridComponent.vue';
import { CONSTANTE_STATUS_OS } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_OS } from '@/constantesComboBox.js'
import ViewRelatorio from '@/views/components/ViewRelatorioJasper'

const URL_SERVLET = "/OrdemServicoRest";

export default {
  components: {
    AgGridVue,
    BtnAggridComponentRenderer,
    ViewRelatorio
  },
  props: {
    numeroOrdemServico: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      popUpSelecao : {
          popupActive: false,
          exibirMensagem: false,
          mensagem: null          
      },
      filtro : { tecnico:  null, status : null, tipoOS: null , comissaoPaga: false } ,
      configuracaoGrid: { 
          context: null, 
          gridApi: null, gridOptions: {
              suppressRowClickSelection: true,
              onCellClicked: (e) => {
                  if (e.column.colId !== 'params') { // cell is from non-select column
                      e.node.setSelected(true);
                  }
              }
          }, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [        
            { headerName: '' , field: 'numero', cellRendererFramework: BtnAggridComponentRenderer, colId: 'params', editable: false, width: 18},     
            { headerName: 'TÉCNICO', field: 'nomeTecnico', width: 75 },           
            { headerName: 'OS', field: 'numeroOS', width: 75 },           
            { headerName: 'Cliente', field: 'nomeCliente', width: 150},           
            { headerName: 'Placa', field: 'placa', width: 75},           
            { headerName: 'Tipo', field: 'tipo', width: 75},           
            { headerName: 'Status', field: 'status', width: 75},                       
            { headerName: 'Execução', field: 'dataExecutada', width: 75,
              valueGetter: function(params) { 
                return params.data.dataExecutada + (params.data.horaExecutada && params.data.horaExecutada !== '__:__'  ? ' ' + params.data.horaExecutada : '');
              }
            },
            { headerName: 'Faturada', field: 'dataFatura', width: 75},
            { headerName: 'Com. Instalação', field: 'valorComissaoInstalacao', width: 75},
            { headerName: 'Com. Bloqueio', field: 'valorComissaoBloqueio', width: 75},
            { headerName: 'Com. Sensor', field: 'valorComissaoSensor', width: 75},
          ]
      },
      
      dadosDaGrid: [],
      totalRegistros: 0,
      listaOSSelecionadas: [],   
    }
  },  
  created() {
      this.CONSTANTE_STATUS_OS = CONSTANTE_STATUS_OS;
      this.CONSTANTE_TIPO_OS = CONSTANTE_TIPO_OS;
  },
  computed:{
     isPodeDarBaixaPgto() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "ADMINISTRADOR" || perfilLogado === "FINANCEIRO" || perfilLogado === "EMPRESA" ) {
            return true;
        }
        return false;
      }, 
      qtdOSPesquisadas() {
        if (this.dadosDaGrid && this.dadosDaGrid.length) {
            let arrFiltrado = this.dadosDaGrid.filter((item) => {
                return item.id;
            });

            return arrFiltrado.length;
        } else {
            return 0;
        }
      }
  },
  methods: {   
    adicionarTodos() {
        for (var i= 0; i < this.dadosDaGrid.length; i++){
            if (this.listaOSSelecionadas.indexOf(this.dadosDaGrid[i]) === -1 && this.dadosDaGrid[i].id) {
                this.listaOSSelecionadas.push(this.dadosDaGrid[i]);
            } 
        }
    },
    limparListaSelecao() {
        this.listaOSSelecionadas = null;
        this.listaOSSelecionadas = [];
    },
    abrirPopUpSelecao() {
        this.popUpSelecao.popupActive = true;        
    },
    adcionarOS(pItem) {
        if (this.listaOSSelecionadas.indexOf(pItem) === -1) {
            this.listaOSSelecionadas.push(pItem);
        } else {
            this.$vs.dialog({color:'danger', title: 'Atenção', text: 'OS já incluída', acceptText:'OK' }) 
        }
    },
    removerOS(pItem) {
        this.listaOSSelecionadas.splice(this.listaOSSelecionadas.indexOf(pItem), 1);
    },
    limparFiltros() {
      this.filtro = { tecnico:  null, status : null, tipoOS: null , comissaoPaga: false };      
    },
    fetchLista(pFiltro) {    
      if (!this.filtro.tecnico && !this.filtro.status && !this.filtro.tipoOS)  {
          this.$vs.notify({
            title: "Mensagem", text: 'Informe pelo menos um parâmetro', time: 5000,
            iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            return;
      }

      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      params.append('orderField', 'nomeTecnico, Clientes.nome'); 
                  
      if (pFiltro.tipoOS && pFiltro.tipoOS.data) {  params.append('filter2', pFiltro.tipoOS.data); }
      if (pFiltro.status && pFiltro.status.data) {  params.append('filter3', pFiltro.status.data); }
      if (pFiltro.tecnico) {  params.append('filter5', pFiltro.tecnico); }
      params.append('filter6', pFiltro.comissaoPaga);

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
                let listaOS = response.data;
                this.dadosDaGrid = [];         
                this.totalRegistros = this.dadosDaGrid.length;  
                let tecnicoAnterior = "";
                let totalComInstalacao = 0, totalComBloqueio = 0, totalComSensor = 0;
                let totais = { valorComissaoInstalacao: 0, valorComissaoBloqueio: 0, valorComissaoSensor: 0 , dataExecutada: "",  horaExecutada: "" };

                listaOS.forEach((row) => {
                        if (tecnicoAnterior != "" && tecnicoAnterior !== row.nomeTecnico) {
                            totais = { valorComissaoInstalacao: 0, valorComissaoBloqueio: 0, valorComissaoSensor: 0 , dataExecutada: "",  horaExecutada: "" };
                            totais.valorComissaoInstalacao = generico.formatarMoedaReal(totalComInstalacao);
                            totais.valorComissaoBloqueio = generico.formatarMoedaReal(totalComBloqueio);
                            totais.valorComissaoSensor = generico.formatarMoedaReal(totalComSensor);
                            totais.dataExecutada = "TOTAL [ " + generico.formatarMoedaReal(totalComInstalacao + totalComBloqueio + totalComSensor) + "]";
                            totais.horaExecutada = '__:__';
                            this.dadosDaGrid.push(totais);
                            totalComInstalacao = 0;
                            totalComBloqueio = 0;
                            totalComSensor = 0;
                        }
                            
                        tecnicoAnterior = row.nomeTecnico;
                    
                        totalComInstalacao = totalComInstalacao + parseFloat(row.valorComissaoInstalacao.replace(",", "."));
                        totalComBloqueio = totalComBloqueio + parseFloat(row.valorComissaoBloqueio.replace(",", "."));
                        totalComSensor = totalComSensor + parseFloat(row.valorComissaoSensor.replace(",", "."));
                        this.dadosDaGrid.push(row);
                });
                        
                totais = { valorComissaoInstalacao: 0, valorComissaoBloqueio: 0, valorComissaoSensor: 0 , dataExecutada: "",  horaExecutada: "" };
                totais.valorComissaoInstalacao = generico.formatarMoedaReal(totalComInstalacao);
                totais.valorComissaoBloqueio = generico.formatarMoedaReal(totalComBloqueio);
                totais.valorComissaoSensor = generico.formatarMoedaReal(totalComSensor);
                totais.dataExecutada = "TOTAL [ " + generico.formatarMoedaReal(totalComInstalacao + totalComBloqueio + totalComSensor) + "]";
                totais.horaExecutada = '__:__';
                this.dadosDaGrid.push(totais);
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'OrdemServico.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    },    
    baixarPagamento() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');

        for (var i= 0; i < this.listaOSSelecionadas.length; i++){
            params.append('idsOS', this.listaOSSelecionadas[i].id);   
        }
                
        axios.post('/BaixaPagamentoComissaoOS', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {                    
                    this.$vs.notify({title: "Erro", text: response.data.error, time: 10000,iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
                } else {                    
                    this.fetchLista(this.filtro);
                    this.limparListaSelecao()
                    this.popUpSelecao.popupActive = false;   
                    this.$vs.notify({title: "Mensagem", text: response.data.mensagem, time: 10000, iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - baixarPagamento - " + error); 
        })  
    }, 
    
  },
  beforeMount() {
     this.configuracaoGrid.context = { componentParent: this };    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();                   
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }
</style>

<style lang="scss">  
.con-vs-dialog {    
    .vs-dialog {
      max-width: 1000px;      
    }
}

.con-vs-popup { 
    .vs-popup {
        min-width: 800px;
        max-width: 1000px;      
    }
}
</style>
