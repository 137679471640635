<template>
    <div class="tableFixHead">
        <div style="position: sticky; right: 10px; left: 90px; top: 0px; z-index: 2; ">
            <vx-card >
                <div class="flex" >                          
                    <vs-button @click="page = 1" icon-pack="feather" icon="icon-chevrons-left" color="dark" :disabled="page == 1 || !dadosPDF"></vs-button>
                    <vs-button @click="page = page - 1" icon-pack="feather" icon="icon-chevron-left" class="ml-2" color="dark" :disabled="page == 1 || !dadosPDF"></vs-button>
                    <vs-button @click="page = page + 1" icon-pack="feather" icon="icon-chevron-right" class="ml-2" color="dark" :disabled="page == pageCount || !dadosPDF"></vs-button>  
                    <vs-button @click="page = pageCount" icon-pack="feather" icon="icon-chevrons-right" class="ml-2" color="dark" :disabled="page == pageCount || !dadosPDF"></vs-button>  
                    <vs-button @click="zoomOut" icon-pack="feather" icon="icon-zoom-out" class="ml-8" color="rgb(62, 201, 214)" :disabled="stylePDFWidth === 30 || !dadosPDF"></vs-button> 
                    <vs-button @click="zoomIn" icon-pack="feather" icon="icon-zoom-in" class="ml-2" color="rgb(62, 201, 214)" :disabled="stylePDFWidth === 100 || !dadosPDF"></vs-button>               
                    <vs-button @click="$refs.pdf.print()" icon-pack="feather" icon="icon-printer" class="ml-8" color="dark" :disabled="!dadosPDF"></vs-button>
                    <vx-tooltip text="Exportar para PDF" position="bottom">               
                    <vs-button @click="exportar('exportarPDF')" icon="picture_as_pdf" class="ml-2" color="danger" :disabled="!dadosPDF"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Exportar para Excel" position="bottom">               
                    <vs-button @click="exportar('exportarXLS')" icon="description" color="success" class="ml-2" :disabled="!dadosPDF"></vs-button>
                    </vx-tooltip>                    
                    <vx-tooltip text="Exportar para RTF" position="bottom">               
                    <vs-button @click="exportar('exportarRTF')" icon="description" color="#05386B" class="ml-2" :disabled="!dadosPDF" v-if="false"></vs-button>
                    </vx-tooltip>
                    <h5 class="ml-6 mt-2" v-if="dadosPDF"> Página {{ currentPage }} de {{pageCount}}</h5>
                    <h5 class="ml-6 mt-3" v-if="dadosPDF"> zoom: {{stylePDFWidth}}%</h5>
                </div>
            </vx-card>
        </div>    
        <vx-card style="position: relative; top: 35px;">
                <vs-alert color="danger" title="Atenção" :active.sync="exibirMensagemNenhumDadoEncontrado" closable icon-pack="feather" close-icon="icon-x">
                    Não existem dados para os parâmetros informados.
                </vs-alert>
                <pdf v-if="dadosPDF" ref="pdf" :src="dadosPDF" :page="page" @num-pages="pageCount = $event" @page-loaded="currentPage = $event" :style="{display: stylePDFDisplay, width: stylePDFWidth+'%'}" style="position: relative; border-style: solid; border-width: 1px;"></pdf>
        </vx-card>
    </div>
</template>

<script>

export default {
    props: {
        sourcePDF: {
            type: Object,
            required: true        
        }
    },
    data() {
        return { 
            page: 1,
            currentPage: 0,
            pageCount: 0,      
            loadedRatio: 0,
            stylePDFDisplay: 'inline-block',
            stylePDFWidth: 100,
            dadosPDF: null,
        }
    },
    computed: {
        exibirMensagemNenhumDadoEncontrado() {
            return this.dadosPDF == null;
        }
    },
    mounted() {
        this.dadosPDF = this.sourcePDF;
    },
    methods: {
        zoomIn() {
            if (this.stylePDFWidth < 100) {
                this.stylePDFWidth = this.stylePDFWidth + 10;
            }
        }, 
        zoomOut() {
            if (this.stylePDFWidth > 0) {
                this.stylePDFWidth = this.stylePDFWidth - 10;
            }
        },    
        exportar(tipo){
            this.$emit(tipo);
        },
        
    }
}
</script>

